//Chakra UI
import {
  Box,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'

//Components
import { Dots } from "./dots"
// React
import { Link } from "gatsby"
import React from "react"
import { getWordCount } from "./helpers"

const Featured = ({ featured }) => {

  const categories = featured.categories.nodes

  return (
      <>
        {categories.map((cat)=> {
          if(cat.slug === "news"){
            return <Link to={"/news" + featured.uri} key="id" _hover={{ textDecor: 'none' }} role="group">
                  <Stack spacing="8" pb="16" >
                    <Box overflow="hidden">
                      <Image
                        src={featured.featuredImage.node.sourceUrl}
                        alt=""
                        width="full"
                        height="15rem"
                        objectFit="cover"
                        transition="all 0.2s"
                        borderRadius="4px"
                        _groupHover={{ transform: 'scale(1.05)' }}
                      />
                    </Box>
                    <Stack spacing="3">
                      <Heading fontSize="16px !important" color="gray.900"> {featured.title} </Heading>
                      <Text color="gray.500" dangerouslySetInnerHTML={{__html: featured.excerpt}} /> 
                      <Stack direction="row" align="center">
                        <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {featured.author.node.name} </Text>
                        <Dots></Dots>
                        <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {featured.date}  </Text>
                        <Dots></Dots>
                        <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {getWordCount(featured.content) + " min read"}  </Text>
                      </Stack> 
                    </Stack>
                  </Stack>
                </Link>
          } else if (cat.slug === "insights") {
            return <Link to={"/insights" + featured.uri} key="id" _hover={{ textDecor: 'none' }} role="group">
              <Stack spacing="8" pb="16" >
                <Box overflow="hidden">
                  <Image
                    src={featured.featuredImage.node.sourceUrl}
                    alt=""
                    width="full"
                    height="15rem"
                    objectFit="cover"
                    transition="all 0.2s"
                    borderRadius="4px"
                    _groupHover={{ transform: 'scale(1.05)' }}
                  />
                </Box>
                <Stack spacing="3">
                  <Heading as="h5" fontSize="16px !important" color="gray.900"> {featured.title} </Heading>
                  <Text color="gray.500" dangerouslySetInnerHTML={{__html: featured.excerpt}} /> 
                  <Stack direction="row" align="center">
                    <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {featured.author.node.name} </Text>
                    <Dots></Dots>
                    <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {featured.date}  </Text>
                    <Dots></Dots>
                    <Text color="gray.600" fontWeight="700" fontFamily="Gotham Medium" fontSize="12px"> {getWordCount(featured.content) + " min read"}  </Text>
                  </Stack> 
                </Stack>
              </Stack>
            </Link>
          }
        }
      )}
    </>
  )
}

export default Featured