import * as React from 'react'
//Chakra UI
import { Text } from '@chakra-ui/react'

export function getWordCount(str) {
        var wordCount = str.split(' ')
       .filter(function(n) { return n !== '' })
       .length;
       
        var readingMinutes = wordCount / 250;
        var round = Math.round(readingMinutes);
        return round;
  }
    
  export function areThereAnyTopics(topics) {
    if (topics.length === 0) {
     return <></> 
    } else {
      return <Text color="base.50" fontSize="xs" mb="0px !important" fontWeight="bold" fontFamily="Gotham Medium" >TOPICS</Text>
    }
  }