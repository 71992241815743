import React from 'react'

export const Dots = () => {
    const separator = {
        width: "4px",
        height: "4px",
        backgroundColor: "#61CC80",
      marginLeft: "12px",
        marginRight: "4px"
    }
  return (
    <div style={separator}></div>
  )
}
