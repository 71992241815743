import React from 'react';
import { IconBasket, IconCash, IconAffiliate, IconSitemap, IconBrandGoogleAnalytics, IconBusinessplan } from '@tabler/icons';

  const icons = {
    basket: IconBasket,
    cash: IconCash,
    affiliate: IconAffiliate,
    sitemap: IconSitemap,
    google: IconBrandGoogleAnalytics,
    plan: IconBusinessplan
  }

  const PostIcon = ({iconName}) => {
    const Icon = icons[iconName];
    return <Icon height="5xl" width="48px" strokeWidth="1px"/>
  }

export default PostIcon;