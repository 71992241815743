//Chakra UI
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
// React 
import React, {useEffect} from 'react'

import { Feature } from '../components/iconsFeature/feature'
import Featured from '../components/featuredPosts'
import { Helmet } from "react-helmet"
// Components
import { IconChevronDown } from "@tabler/icons"
import Layout from '../layout'
import PageTransition from 'gatsby-plugin-page-transitions'
import PostIcon from '../components/iconsFeature/icons'
import Svg from '../images/statys-background-animation-large.svg'
import SvgSmall from '../images/statys-background-animation-small-vertical.svg'
import { graphql } from 'gatsby'
import { navigate } from "@reach/router"

const About = ({ data }) => {

  const isMobile = useBreakpointValue({ base: true, xl: false })
  const items = data.wpPage.about
  const solutions = data.wpPage.about.solutions

  const featuredPosts = data.allWpPost.nodes
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MDCONNECT DMCC</title>
        </Helmet>
      <PageTransition>
      {!isMobile && ( <Image src={Svg} style={{position: `absolute`, top: `0`, left: `0`, width: "25%"}} ></Image>)}
   
      {/* FIRST SECTION */}
      <Box as="section" pt="164px" pb="40">
         <Flex m="auto" maxW="620px" flexDirection="column" alignItems="center" justifyContent="center">
            <Heading textAlign="center" fontSize="60px !important" color="blue.600" fontWeight="700" fontFamily="Gotham Light">{items.heading}</Heading>
            <Text textAlign="center" color="gray.600" pt="6" fontSize="18px" lineHeight={7} > {items.text} </Text>
            <Link href="#solutions" pt="40px" textDecorationLine="none">
                <Button variant="outline" rightIcon={<IconChevronDown width="16px" height="16px" margin="4px !important" />}> {items.buttonLabel}</Button>
            </Link>
        </Flex>
      </Box>

      {/* SECOND SECTION - ICONS */}
      <Box as="section" id="solutions" py="20">
        <Container maxW="container.md">
          <Stack direction="row" justify={{ base: 'center', md: "space-between"}} alignItems="center" pb="16px">
            <Stack spacing={{ base: '4', md: '5' }}>
              <Stack spacing="3" textAlign={{base: "center", md: "left"}}>
                <Heading as="h5" fontSize="36px !important" color="blue.600" fontWeight="400">
                  {items.headingTwo}
                </Heading>
              </Stack>
              <Text fontSize="lg" color="gray.600" textAlign="center">
              {items.textTwo}
              </Text>
            </Stack>
          {!isMobile && (
            <Link href='/contact/'>
              <Button variant="solid" size="lg">
                Contact Us
              </Button>
            </Link>
            )}
          </Stack>
          <Divider ></Divider>
          <SimpleGrid pt="40px" columns={{ base: 1, md: 2, }} spacingX="10" spacingY={{ base: '8', md: '14', }}>
            {solutions.map((solution) =>
              <Feature key={solution.icon} icon={<PostIcon iconName={solution.icon} />}>{solution.text} </Feature>
            )}
          </SimpleGrid>
          </Container>
        </Box>

        {/* THIRD SECTION - BLUE */}
        <Box as="section" py="20" bg="blue.600" style={{ position: `relative` }}>
          
          {!isMobile && ( <Image src={SvgSmall} style={{position: `absolute`, top: `0`, right: `0`, height: `349px`, opacity: `0.4`}} ></Image>)}
        
          <Container maxW="container.md" >
            <Flex m="auto"  flexDirection="column" alignItems="center" justifyContent="center">
              <Text textAlign="center" fontSize="4xl" mb="0px !important" color="base.50" fontFamily="Gotham Light" >{items.headingThree}</Text>
              <Text textAlign="left" color="whiteAlpha.700" pt="6" fontSize="lg">  {items.textThree} </Text>
              {/* <Link href={items.buttonThreeLink.url} pt="40px" textDecorationLine="none">
                <Button variant="solidOutline" color="base.50" fontSize="18px" > {items.buttonThreeLabel}</Button>
              </Link> */}
            </Flex>
          </Container>
        </Box>
      
        <Box bg="bg-surface">
          <Container py={{ base: '16', md: '20' }} maxW="container.xl">
            <Stack spacing={{ base: '9', md: '9' }}>
              <Stack direction="row" justify="space-between" alignItems="center">
                <Stack spacing={{ base: '4', md: '5' }} justifyContent="center">
                  <Stack spacing="3">
                    <Heading color="blue.600" fontSize="4xl" fontWeight="300" marginTop="16px !important">
                      Featured Articles
                    </Heading>
                  </Stack>
                </Stack>
                  {!isMobile && (
                  <Link href="/insights/">
                    <Button variant="solid" size="lg">
                      Show all
                    </Button>
                  </Link>
                )}
              </Stack>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} gap={{ base: '12', lg: '8' }}>
                {featuredPosts.map((featured) =>
                  <Featured featured={featured}> </Featured>)}
              </SimpleGrid>
                {isMobile && (
                <Link href="/insights/" margin={{base: "0px auto !important"}}>
                  <Button variant="solid" size="lg" >
                    Show all
                  </Button>
                </Link>
              )}
            </Stack>
          </Container>
        </Box>
        </PageTransition>
   </Layout>
  )
}

export default About

// GRAPHQL
export const query = graphql`
  {
    wpPage(title: {eq: "About"}) {
      content
      about {
        heading
        text
        buttonLabel
        buttonLink {
          title
          url
        }

        headingTwo
        textTwo
        solutions {
          icon
          text
        }

        headingThree
        textThree
        buttonThreeLabel
        buttonThreeLink {
          url
        }
      }
    }
    allWpPost(filter: {isSticky: {eq: true}}) {
      nodes {
        link
        slug
        isSticky
        title
        uri
        date(formatString: "MMMM DD, YYYY")
        excerpt
        content
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`