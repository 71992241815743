import { Box, Stack, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'

export const Feature = (props) => {
  const { children, icon } = props
  return (
    <Stack spacing={{base: '3',md: '6',}} direction={{base: 'column',md: 'row',}} alignItems="center">
      <Box fontSize="5xl" color="blue.300">{icon}</Box>
      <Stack spacing="1">
        <Box color={mode('gray.600', 'gray.400')}  fontFamily="Gotham Medium" fontSize="18px" textAlign={{base: "center", md: "left"}} >{children}</Box>
      </Stack>
    </Stack>
  )
}